@use 'sass:color'

$c-black: #0d1317

$c-white: #F9F9FA

$c-primary-100: #2aabee

$c-primary-8: rgba($c-primary-100, 0.08)
$c-primary-10: rgba($c-primary-100, 0.10)
$c-primary-12: rgba($c-primary-100, 0.12)
$c-primary-16: rgba($c-primary-100, 0.16)
$c-primary-32: rgba($c-primary-100, 0.32)
$c-primary-56: rgba($c-primary-100, 0.56)
$c-primary-80: rgba($c-primary-100, 0.8)
$c-primary-116: color.mix(black, $c-primary-100, 16%)
$c-primary-110: color.mix(black, $c-primary-100, 10%)
$c-primary-surface: #E1EAFF

$c-grey-100: #F7F8FA

$c-background-surface-100: #3e5072
$c-background-surface-4: rgba($c-background-surface-100, 0.04)
$c-background-surface-8: rgba($c-background-surface-100, 0.08)
$c-background-surface-12: rgba($c-background-surface-100, 0.12)
$c-background-surface-16: rgba($c-background-surface-100, 0.16)
$c-background-surface-32: rgba($c-background-surface-100, 0.32)

$c-base-100: #000b26
$c-base-80: rgba($c-base-100, 0.80)
$c-base-72: rgba($c-base-100, 0.72)
$c-base-60: rgba($c-base-100, 0.60)
$c-base-52: rgba($c-base-100, 0.52)
$c-base-48: rgba($c-base-100, 0.48)
$c-base-40: rgba($c-base-100, 0.40)
$c-base-32: rgba($c-base-100, 0.32)
$c-base-20: rgba($c-base-100, 0.20)
$c-base-16: rgba($c-base-100, 0.16)
$c-base-12: rgba($c-base-100, 0.12)
$c-base-06: rgba($c-base-100, 0.06)
$c-base-04: rgba($c-base-100, 0.04)

$c-error: #FF4545
$c-success: #18bf7d

$c-shadow-100: #0e286d
$c-shadow-12: rgba($c-shadow-100, 0.12)
$c-shadow-08: rgba($c-shadow-100, 0.08)

$c-button-shadow: 0px 2px 8px $c-shadow-12
$c-background-base: #f2f4f7
