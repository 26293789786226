@import "~@angular/cdk/overlay-prebuilt.css"
@import "~ngx-toastr/toastr"

@import '~@technokratos/components-styles/reset'
@import '~@technokratos/components-styles/components/index'
@import '~@technokratos/components-styles/components/select'

@import 'prometheus-theme/index'

@import "mixins"
@import "color"
@import "typographics"

body
	font-family: 'Inter', sans-serif
	font-style: normal
	font-weight: normal
	font-variant-ligatures: none
	text-rendering: optimizeLegibility
	font-smoothing: antialiased
	font-smooth: always
	-webkit-text-size-adjust: 100%
	overflow-x: hidden

.toast-container
	position: fixed
	display: grid
	row-gap: 10px
	bottom: 52px
	z-index: 999999
	left: 0
	right: 0
	
	&:has(.custom-toast-class)
		pointer-events: auto

tk-icon svg
	display: block
	fill: currentColor

cdk-dialog-container
	outline: none
	border-radius: 16px !important
	padding: 28px 0 !important
	background: white !important

	box-shadow: 0px 4px 24px rgba(#0e276d, 0.12), 0px 4px 24px rgba(black, 0.06), 0px 4px 12px rgba(#0e276d, 0.08)

cdk-row, cdk-header-row, cdk-footer-row
	display: flex
	background: white
	padding: 17px 9px
	font-size: 14px
	line-height: 20px
	color: $base-88

cdk-cell, cdk-header-cell, cdk-footer-cell
	flex: 1

cdk-row, cdk-header-row
	box-shadow: inset 0px -1px 0px rgba(#3e5072, 0.16)

cdk-header-row
	@include content-14
	color: $base-40

.backdrop-transparent
	background: transparent
