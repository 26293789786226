$base: #000b26

$base-96: rgba($base, 96%)
$base-88: rgba($base, 88%)
$base-80: rgba($base, 80%)
$base-72: rgba($base, 72%)
$base-64: rgba($base, 64%)
$base-56: rgba($base, 56%)
$base-48: rgba($base, 48%)
$base-40: rgba($base, 40%)
$base-32: rgba($base, 40%)
$base-16: rgba($base, 16%)
$base-8: rgba($base, 8%)

$black-72: #474f62

$red-72: #fc1414b8

$background-white: #ebebee
$background-base: #f2f4f7

$neutral: #172b4d

$system-alert: #ff9500
$system-error: #ff4545
$system-success: #18bf7d

$system-blue: #2aabee

$form-grey: #3e5072
$form-second-grey: #dfe1e4

$form-grey-4: rgba(#3e5072, 4%)
$form-grey-8: rgba(#3e5072, 8%)
