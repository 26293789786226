@import "mixins"

.tk-tooltip
	color: $c-base-100
	padding: 14px
	background: white
	border-radius: 8px
	font-size: 14px
	font-weight: 500
	line-height: 20px
	pointer-events: none
	position: fixed
	max-width: 420px
	top: 0
	left: 0

	&__arrow
		position: absolute
		content: ""
		border-style: solid
		border-width: 5px

	&[data-placement=top] .tk-tooltip__arrow
		top: 100%
		border-color: rgba(white, 0.88) transparent transparent transparent

	&[data-placement=bottom] .tk-tooltip__arrow
		bottom: 100%
		border-color: transparent transparent rgba(white, 0.88) transparent

	&__content > div
		display: inline
