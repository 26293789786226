.tk-loader
	width: 24px
	height: 24px

	&--appearance-circle > div
		animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
		border: 4px solid
		border-color: currentColor transparent transparent transparent

		&:nth-child(1)
			animation-delay: -0.45s

		&:nth-child(2)
			animation-delay: -0.3s

		&:nth-child(3)
			animation-delay: -0.15s

	&--appearance-dots
		& > div
			width: 6px
			height: 6px
			animation: fade 0.9s infinite linear
			background: currentColor

			&:nth-child(1)
				animation-delay: -0.3s
				margin-left: -3px

			&:nth-child(2)
				animation-delay: -0.6s

			&:not(:last-child)
				margin-right: 6px

@keyframes ring
	0%
		transform: rotate(0deg)

	100%
		transform: rotate(360deg)


@keyframes fade
	0%, 100%
		opacity: 0.4

	50%
		opacity: 1

