@import "mixins"

.tk-option
	font-size: 14px
	line-height: 20px
	padding: 8px 20px
	color: $c-base-72

	&__check-icon
		flex: 0 0 auto
		color: #1c55e2
		opacity: 0
		transition: opacity 0.12s ease-in-out
		margin-left: 12px

		&--visible
			opacity: 1

	&:hover
		background-color: $c-background-base

	&:focus-within
		outline: none
		background-color: $c-background-base

	&--multiple
		justify-content: flex-start
