@import "mixins"

.tk-radio-group
	&--vertical
		.tk-radio-button:not(:last-child)
			margin-bottom: 16px

	&--horizontal
		.tk-radio-button:not(:last-child)
			margin-right: 16px

.tk-radio-button
	color: $c-base-72
	font-size: 14px
	line-height: 20px

	&__wrapper
		&--not-reverse .tk-radio-button__circle
			margin-right: 10px

		&--reverse .tk-radio-button__circle
			margin-left: 10px

	&__circle
		&-outer
			border-radius: 50%
			width: 20px
			height: 20px
			background: $c-base-16

		&-inner
			position: absolute
			left: 50%
			top: 50%
			border-radius: 50%
			width: 8px
			height: 8px
			margin-left: -4px
			margin-top: -4px
			background: white
			transition: transform 0.16s ease-in-out
			transform: scale(2.125)

	&__suffix
		margin-left: 10px

	&--filled
		padding: 16px
		background: $c-grey-100
		border-radius: 8px

		&:hover .tk-radio-button__wrapper
			color: $c-primary-100

	&--inline
		&:hover .tk-radio-button__wrapper
			color: $c-base-100

	&--checked
		.tk-radio-button__circle-outer
			background: $c-primary-100

		.tk-radio-button__circle-inner
			transform: scale(1)

		&.tk-radio-button--filled
			background: $c-primary-surface
