@import "mixins"

.tk-label
	font-size: 16px
	line-height: 20px

.tk-label, .tk-input::placeholder
	color: $c-base-40

.tk-input
	font-size: 14px
	line-height: 20px

.tk-form-field
	margin-bottom: 32px

	.tk-label
		top: 20px
		left: 16px
		right: 16px
		transition: transform 0.16s, font-size 0.16s, color 0.12s

	.tk-input
		padding: 20px 16px

		&:-webkit-autofill
			animation-name: onAutoFillStart

		&:not(:-webkit-autofill)
			animation-name: onAutoFillCancel

	@keyframes onAutoFillStart
		from

	@keyframes onAutoFillCancel
		from

	input.tk-input, textarea.tk-input
		border-radius: 8px

	&__flex
		box-shadow: inset 0 0 0 1px $c-base-16
		border-radius: 8px
		font-size: 16px
		color: $c-base-100
		transition: box-shadow 0.12s, border-color 0.12s
		line-height: 20px
		width: 100%
		background: white

	&__counter
		color: $c-base-40
		padding: 2px
		background-color: white
		line-height: 14px
		font-size: 11px
		font-weight: 500
		right: 15px
		margin-top: -10px

	&__prefix, &__suffix
		display: flex
		align-items: center

		& > * ~ *
			margin-left: 16px

	&__prefix
		padding-left: 16px

	&__suffix
		padding-right: 16px

	&:hover:not(.tk-form-field--disabled, .tk-form-field--focused, .tk-form-field--invalid)
		.tk-form-field__flex
			box-shadow: inset 0 0 0 1px $c-base-40

	&--disabled .tk-form-field__flex
		background: $c-base-04
		box-shadow: inset 0 0 0 0 transparent

	// иначе простой ховер с бОльшим весом
	&--focused, &--focused:hover
		.tk-form-field__flex
			box-shadow: inset 0 0 0 2px $c-primary-100

	// иначе простой ховер с бОльшим весом
	&--invalid, &--invalid:hover
		.tk-form-field__flex
			box-shadow: inset 0 0 0 2px $c-error

	&--has-label .tk-input
		padding: 29px 16px 11px

	&--label-float .tk-label
		font-size: 12px
		transform: translate3d(0px, -10px, 0)

	.tk-hint, .tk-error
		padding: 8px 16px 0

.tk-hint, .tk-error
	padding: 8px 0 0
	font-size: 14px
	line-height: 20px
	display: block

.tk-hint
	color: $c-base-40

.tk-error
	color: $c-error
