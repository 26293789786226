@import "prometheus-theme/mixins"
@import "color"

@mixin content-12
	font-size: 12px
	line-height: 16px

@mixin content-13
	font-size: 12px
	line-height: 16px

@mixin content-14
	font-size: 14px
	line-height: 20px

@mixin content-14-medium
	font-size: 14px
	line-height: 20px
	font-weight: 500

@mixin content-16
	font-size: 16px
	line-height: 24px

@mixin content-18
	font-size: 18px
	line-height: 26px

@mixin content-20
	font-size: 20px
	line-height: 24px

@mixin h1
	font-weight: 500
	font-size: 32px
	line-height: 40px

@mixin h2
	font-weight: 500
	font-size: 24px
	line-height: 32px

@mixin h3
	font-weight: 500
	font-size: 20px
	line-height: 28px

@mixin link-underline($color: $base-64)
	position: relative

	&:before
		content: ''
		width: 100%
		height: 1px
		background-color: $color
		position: absolute
		bottom: 0
		left: 0
