@import "mixins"

.tk-calendar
	width: 304px
	height: 418px
	display: flex
	flex-direction: column
	position: relative

	::-webkit-scrollbar
		width: 0
		background: transparent

	&__viewport
		flex: 1 0 0
		width: 100%
		overflow: auto

	&__header
		padding: 8px
		color: rgba($c-black, 0.64)
		background: #fff
		width: 100%
		display: flex
		justify-content: space-between
		flex: 0 0 auto

	&__year
		font-size: 14px
		line-height: 20px
		color: rgba($c-black, 0.64)

	&__weekdays
		color: $c-base-32
		border-bottom: 1px solid rgba($c-black, 0.08)
		font-size: 12px
		line-height: 14px
		padding: 0 16px
		height: 32px
		display: flex
		justify-content: space-around
		text-transform: uppercase
		background: #fff
		flex: 0 0 auto

	&__weekday
		width: 32px

		&:nth-child(6), &:nth-child(7)
			color: rgba(#fc1414, 0.4)

	&__option
		font-family: inherit
		text-align: left
		font-size: 13px
		line-height: 16px
		padding: 4px 8px
		color: $c-base-72
		margin-bottom: 4px

		&:hover
			background: $c-background-surface-8

		&--active
			background: $c-primary-8
			color: $c-primary-100

		&--year
			width: 100%

	&__dropdown
		&-panel
			padding: 4px 0
			border-radius: 0 0 8px 8px
			position: relative

		&-arrow
			color: $c-base-40
			margin-left: 4px
			transition: transform 0.12s

			&--rotate
				transform: rotate(180deg)
				color: $c-primary-100

		&-trigger
			padding: 8px
			display: flex
			align-items: center
			font-weight: 500
			font-size: 16px
			line-height: 22px
			color: $c-base-72
			border-radius: 8px 8px 0 0

			&--active
				color: $c-primary-100
				box-shadow: inset 0px -1px 0px $c-background-surface-16, 0px 4px 12px rgba($c-shadow-100, 0.14)

			&:hover
				color: $c-primary-100

				.tk-calendar__dropdown-arrow
					color: $c-primary-100

		&-viewport
			height: 354px
			width: 100%
			-ms-overflow-style: none
			scrollbar-width: none
			position: relative
			overflow: auto

			&::-webkit-scrollbar
				display: none

		&-nav
			position: absolute
			z-index: 2
			display: flex
			align-items: center
			justify-content: center
			height: 24px
			width: 100%
			color: $c-base-40

			&--prev
				top: 0
				background: linear-gradient(to bottom, white 8px, rgba(white, 0))

				.tk-icon
					transform: rotate(180deg)

			&--next
				bottom: 0
				background: linear-gradient(to top, white 8px, rgba(white, 0))


	&__footer
		display: flex
		justify-content: center
		align-items: center
		height: 48px
		border-top: 1px solid rgba($c-black, 0.08)

		background: #fff
		position: absolute
		bottom: 0
		left: 0
		right: 0

		button
			color: $c-base-72
			font-size: 13px
			line-height: 18px

.tk-calendar-month
	&__label
		padding-top: 8px
		height: 40px
		font-weight: 400
		font-size: 16px
		line-height: 22px
		color: $c-base-32
		margin-left: 16px

	&__days
		position: relative
		overflow: hidden
		display: grid
		grid-template-columns: repeat(7, 0fr)
		padding: 4px 0
		margin: 0 12px

	&__day
		position: relative
		width: 32px
		height: 32px
		font-size: 14px
		line-height: 20px
		color: $c-black
		margin: 4px 4px
		display: flex
		align-items: center
		justify-content: center

		&:hover:not(.tk-calendar-month__day--active):not(.tk-calendar-month__day--selected):not(.tk-calendar-month__day--today)
			background: $c-background-surface-4
			border-radius: 8px

		&--weekend, &--vacation:not(#{&}--hidden)
			color: $c-error

		&--vacation:not(#{&}--hidden), &--event:not(#{&}--hidden), &--working:not(#{&}--hidden)
			&:before
				position: absolute
				width: 4px
				content: ''
				height: 4px
				background-color: $c-base-16
				margin-right: auto
				margin-left: auto
				bottom: 2px
				border-radius: 50%

		&--today
			color: $c-primary-100
			font-weight: 500
			border-radius: 16px
			&:hover
				background: $c-primary-12
			&:not(.tk-calendar-month__day--selected)
				background: $c-primary-8

		&--working
			color: $c-black

		&--other
			opacity: 0.32

		&--hidden
			visibility: hidden

		&--selected
			position: relative
			border-radius: 0
			&:hover:not(.tk-calendar-month__day--selection-start):not(.tk-calendar-month__day--selection-end)
				background: $c-primary-16
				border-radius: 8px
			&:before
				content: ""
				border-radius: 0
				width: 32px
				height: 32px
				position: absolute
				background: $c-primary-8
			&:after
				display: block
				content: ""
				border-radius: 0
				width: 8px
				height: 32px
				right: -8px
				position: absolute
				background: $c-primary-8

		&--monday
			&:before
				border-radius: 8px 0 0 8px
				background: $c-primary-8

		&--sunday
			&:before
				border-radius: 0 8px 8px 0

			&:after
				content: none

		&--selection-end
			&:after
				content: none

		&--active, &--selection-start, &--selection-end
			position: relative
			background: $c-primary-80
			color: white
			font-weight: 400
			border-radius: 8px

			&:before
				content: ""
				width: 32px
				height: 32px
				background: $c-primary-8
				position: absolute

		&--selection-start
			&:before
				border-radius: 8px 0 0 8px
				background: $c-primary-8

		&--selection-end
			&:before
				border-radius: 0 8px 8px 0
				background: $c-primary-8

		&--selection-start.tk-calendar-month__day--sunday, &--selection-end.tk-calendar-month__day--monday
			&:before
				border-radius: 8px

		&--selected#{&}--vacation, &--selected#{&}--event, &--selected#{&}--working
			&:after
				width: 40px

		&--monday#{&}--selected#{&}--vacation
			&:after
				border-radius: 8px 0 0 8px

		&--first
			&:before
				border-radius: 8px 0 0 8px

		&--last
			&:before
				border-radius: 0 8px 8px 0
			&:after
				content: none
