@use "sass:selector"
@import "mixins"

.tk-button-group
	& > :not(:last-child)
		margin-right: 12px

.tk-button
	border-radius: 8px

	& > span
		display: inherit

	&[disabled]
		opacity: 0.32
		pointer-events: none

	&--icon#{&}--size-xl
		padding: 0
		width: 56px
		height: 56px

	&--icon#{&}--size-l
		padding: 0
		width: 48px
		height: 48px

	&--icon#{&}--size-m
		padding: 0
		width: 40px
		height: 40px

	&--icon#{&}--size-s
		padding: 0
		width: 32px
		height: 32px

	&--icon#{&}--size-xs
		padding: 0
		width: 28px
		height: 28px

	@at-root #{&}--size-xs
		height: 28px
		padding: 6px 10px
		font-size: 13px
		line-height: 13px

		& > .tk-button__icon--first
			margin-right: 4px

		& > .tk-button__icon--last
			margin-left: 4px

	@at-root #{&}--size-s
		height: 32px
		padding: 8px 16px
		font-size: 14px
		line-height: 16px

		& > .tk-button__icon--first
			margin-right: 6px

		& > .tk-button__icon--last
			margin-left: 6px

	@at-root #{&}--size-m
		height: 40px
		padding: 10px 20px
		font-size: 14px
		line-height: 20px

		& > .tk-button__icon--first
			margin-right: 6px

		& > .tk-button__icon--last
			margin-left: 6px

	@at-root #{&}--size-l
		height: 48px
		padding: 12px 24px
		font-size: 16px
		letter-spacing: -0.25px
		font-weight: 500
		line-height: 24px

		& > .tk-button__icon--first
			margin-right: 8px

		& > .tk-button__icon--last
			margin-left: 8px

	@at-root #{&}--size-xl
		height: 56px
		padding: 16px 24px
		font-size: 16px
		letter-spacing: -0.25px
		font-weight: 500
		line-height: 24px

		& > .tk-button__icon--first
			margin-right: 8px

		& > .tk-button__icon--last
			margin-left: 8px

	@at-root #{&}--theme-primary
		background-color: $c-primary-100
		color: white

		&:hover
			background: $c-primary-110

		&:active
			background: $c-primary-116

	@at-root #{&}--theme-secondary
		background-color: $c-background-surface-8
		color: $c-base-80

		&:hover
			background-color: $c-background-surface-12

		&:active
			background-color: $c-background-surface-16

	@at-root #{&}--theme-ghost
		background-color: white
		border: 1px solid $c-primary-80
		color: $c-primary-100

		&:hover
			background-color: $c-primary-8

		&:active
			background-color: $c-primary-16

	@at-root #{&}--theme-raised
		background-color: white
		color: $c-primary-100
		border: 1px solid transparent
		box-shadow: $c-button-shadow

		&:hover
			border-color: $c-primary-32

		&:active
			border-color: $c-primary-56

	@at-root #{&}--theme-inline
		background-color: white
		color: $c-base-80

		&:hover
			background-color: $c-background-surface-12

		&:active
			background-color: $c-background-surface-16

		& > .tk-button__icon--first
			margin-right: 4px

		& > .tk-button__icon--last
			margin-left: 4px

	@at-root #{&}--theme-link, #{&}--theme-dashed
		width: auto !important
		height: auto !important
		padding: 0
		border-radius: 0

		&.tk-button--size-l, &.tk-button--size-xl
			& > .tk-button__icon--first
				margin-right: 4px

			& > .tk-button__icon--last
				margin-left: 4px

		&.tk-button--size-m, &.tk-button--size-s, &.tk-button--size-xs
			& > .tk-button__icon--first
				margin-right: 2px

			& > .tk-button__icon--last
				margin-left: 2px

	@at-root #{&}--theme-link
		color: $c-primary-100

		&:hover
			color: $c-primary-110

		&:active
			color: $c-primary-116

	@at-root #{&}--theme-dashed
		color: $c-base-80

		& > span
			border-bottom: 1px dashed

		&.tk-button--size-xs, &.tk-button--size-s
			line-height: 16px

		&.tk-button--size-m
			line-height: 20px

		&.tk-button--size-l, &.tk-button--size-xl
			line-height: 24px

		&:hover
			color: $c-primary-110

		&:active
			color: $c-primary-116
