@import "mixins"

.tk-checkbox-group
	&--vertical
		.tk-checkbox:not(:last-child)
			margin-bottom: 10px

	&--horizontal
		.tk-checkbox:not(:last-child)
			margin-right: 32px

.tk-checkbox
	color: $c-base-72
	font-size: 14px
	line-height: 20px

	&__wrapper
		&--not-reverse .tk-checkbox__box
			margin-right: 10px

		&--reverse .tk-checkbox__box
			margin-left: 10px

	&__box
		width: 20px
		height: 20px
		border: 1px solid $c-base-16
		border-radius: 4px
		color: white
		display: flex
		align-items: center
		justify-content: center

	&__icon
		opacity: 0

	&__suffix
		margin-left: 10px

	&--filled
		padding: 16px
		background: $c-grey-100
		border-radius: 8px

		&:hover .tk-checkbox__wrapper
			color: $c-primary-100

	&--inline
		&:hover .tk-checkbox__wrapper
			color: $c-base-100

	&--disabled
		opacity: 0.64
		pointer-events: none

	&--checked, &--indeterminate
		.tk-checkbox__box
			border: 1px solid $c-primary-100
			background-color: $c-primary-100

		.tk-checkbox__icon
			opacity: 1

		&.tk-checkbox--filled
			background: $c-primary-surface
