@import "mixins"

.tk-menu-panel
	padding: 8px 0

	& > *
		font-size: 16px
		line-height: 20px
		padding: 8px 16px
		color: $c-base-100

		&:hover
			color: $c-primary-100
