@import 'mixins'

h2
	font-size: 28px
	height: 32px
	font-weight: 600
	margin: 60px 0

h3
	@include h3
	line-height: 24px
	margin-bottom: 40px

h4
	@include content-18
	line-height: 24px
	font-weight: 500
	margin-bottom: 20px
