@import "mixins"

.tk-badge
    &__background
        &--primary
            color: $c-primary-80
        &--secondary
            color: $c-black
        &--raised
            color: $c-white
            filter: drop-shadow(0px 4px 16px rgba($c-base-100, 0.2))

    &__icon
        &--primary
            color: $c-white
        &--secondary
            color: $c-white
        &--raised
            color: $c-black
